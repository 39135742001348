import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Bingo } from 'assets/images/bingo';
import axios from 'axios';

import s from 'components/ContentBingo/ContentBingo.module.css';
import Modal from 'components/Modal';
import CheckIcon from 'assets/images/check';
import { useStateValue } from 'store/state';
import { SHOW_RESULTS, SELECT_BOX } from 'store/contants';
import { subscribeUserToMailchimpList } from 'utils/services';
import { ReactComponent as Measure } from 'assets/images/measure.svg';
import { FormValues } from 'components/Modal/ModalForm';

let targetElement: HTMLElement | any = null;

const generateBox = (
  bingoNumber: number,
  isSelected: boolean,
): ReactElement => {
  const BoxComponent = Bingo[bingoNumber];
  return (
    <div
      className={`${s.box} ${
        isSelected ? s['box-selected'] : s['box-not-selected']
      }`}
    >
      <div className={s.checkbox}>{isSelected && <CheckIcon />}</div>
      <BoxComponent />
      <div className={s['box-text']}>
        <span>
          <FormattedMessage
            id={`bingo.content.box-${bingoNumber}`}
            values={{
              b: (txt: string) => <span className={s['text-bold']}>{txt}</span>,
            }}
          />
        </span>
      </div>
    </div>
  );
};

const ContentBingo: FunctionComponent = () => {
  const [{ email, results, scrollToTop }, dispatch] = useStateValue();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // Functions
  useEffect(() => {
    targetElement = document.querySelector('.modal');
  }, []);

  const onShowResults = () => {
    if (email) showResults(email);
    else {
      disableBodyScroll(targetElement);
      setIsModalOpen(true);
    }
  };

  const onModalClose = () => {
    enableBodyScroll(targetElement);
    setIsModalOpen(false);
  };

  const onSubmitForm = async (values: FormValues) => {
    subscribeUserToMailchimpList(values.email);
    onModalClose();
    const requestURL =
      'https://hook-4n3czlllfq-ew.a.run.app?target=quizz_legal';
    try {
      await axios({
        url: requestURL,
        method: 'post',
        data: values,
      });
    } catch (error) {
      console.warn(error);
    }
    showResults(values.email);
  };

  const showResults = (email: string) => {
    dispatch({
      type: SHOW_RESULTS,
      showResults: true,
      email,
    });
    scrollToTop();
  };

  const onBoxSelect = (bingoNumber: number) =>
    dispatch({
      type: SELECT_BOX,
      bingoNumber,
      isSelected: !results[bingoNumber].isSelected,
    });

  return (
    <>
      <section className={s.container}>
        <div className={s.content}>
          <div className={s.intro}>
            <FormattedMessage id="bingo.content.title">
              {message => <span className={s.title}>{message}</span>}
            </FormattedMessage>
            <span className={s.subtitle}>
              <FormattedMessage
                id="bingo.content.subtitle"
                values={{
                  b: (txt: string) => (
                    <span className={s['text-bold']}>{txt}</span>
                  ),
                }}
              />
            </span>
          </div>

          <div className={s['grid-container']}>
            {[...Array(9)].map((_, i) => (
              <div key={i} onClick={() => onBoxSelect(i)}>
                {generateBox(i, results[i].isSelected)}
              </div>
            ))}
          </div>

          <div className={s['bilan-container']}>
            <div className={s['gauge']}>
              <Measure />
            </div>
            <span className={s['cta-text']}>
              <FormattedMessage id={'bingo.content.bilan'} />
            </span>
            <span className={s['cta-subtitle']}>
              <FormattedMessage id={'bingo.content.private'} />
            </span>
            <button className={s['cta-button']} onClick={onShowResults}>
              <FormattedMessage id={'bingo.content.result'} />
            </button>
          </div>
        </div>

        <div className={s.divider} />
      </section>

      <Modal
        isOpen={isModalOpen}
        onModalClose={onModalClose}
        onSubmit={onSubmitForm}
      />
    </>
  );
};

export default ContentBingo;
