import React, { FunctionComponent } from 'react';

import Fade from 'hoc/Fade';
import HeaderResults from 'components/HeaderResults';
import ContentResults from 'components/ContentResults';
import FooterResults from 'components/FooterResults';
import { useStateValue } from 'store/state';

const Bingo: FunctionComponent = () => {
  const [{ showResults }] = useStateValue();
  
  if (!showResults) return null;

  return (
    <Fade show>
      <HeaderResults />
      <ContentResults />
      <FooterResults />
    </Fade>
  );
};

export default Bingo;
