import React from 'react';

const RetryIcon = () => (
  <svg width={24} height={29} viewBox="0 0 24 29">
    <title>{'icon-redo'}</title>
    <path
      d="M22.232 11.476C19.88 6.604 14.616 4.14 9.576 5.036l1.344-2.52a1.12 1.12 0 00-.448-1.568 1.12 1.12 0 00-1.568.448L6.16 6.492s-.616 1.008.336 1.456l5.096 2.744c.168.112.392.168.56.168.392 0 .84-.224 1.008-.616a1.12 1.12 0 00-.448-1.568L10.08 7.22c3.976-.672 8.12 1.344 9.968 5.152 2.24 4.648.28 10.248-4.368 12.432-2.24 1.064-4.76 1.232-7.112.392-2.352-.84-4.256-2.52-5.32-4.76-1.064-2.24-1.232-4.76-.392-7.112.224-.616-.112-1.288-.728-1.512-.56-.112-1.232.224-1.456.84-1.008 2.968-.84 6.104.504 8.904 1.344 2.8 3.696 4.928 6.664 5.992 1.288.448 2.576.672 3.864.672 1.736 0 3.472-.392 5.04-1.176 5.824-2.8 8.288-9.8 5.488-15.568z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
);

export default RetryIcon;
