import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';

import fr from './translations/fr-FR.json';
import './index.css';

import App from 'pages/App';

ReactDOM.render(
  <IntlProvider locale="fr" messages={flatten(fr)}>
    <App />
  </IntlProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
