import React, { createRef, FunctionComponent } from 'react';

import { generateEmptyResults } from 'utils/services';
import Bingo from 'pages/Bingo';
import Results from 'pages/Results';
import { SHOW_RESULTS, SELECT_BOX } from 'store/contants';
import { StateProvider } from 'store/state';

interface Action {
  bingoNumber: number;
  email: string;
  isSelected: boolean;
  showResults: boolean;
  type: string;
}

export interface Result {
  isSelected: boolean;
}
interface State {
  email: string;
  results: Result[];
  scrollToTop: () => void;
  showResults: boolean;
}

const App: FunctionComponent = () => {
  const ref = createRef<HTMLInputElement>();

  const initialState = {
    email: null,
    showResults: false,
    results: generateEmptyResults(),
    scrollToTop: () => {
      if (ref.current) ref.current.scrollIntoView();
    },
  };

  const reducer = (state: State, action: Action) => {
    switch (action.type) {
      case SHOW_RESULTS:
        const { showResults, email } = action;
        return {
          ...state,
          showResults,
          ...(email && { email }),
          results: showResults ? state.results : generateEmptyResults(),
        };
      case SELECT_BOX:
        const { bingoNumber, isSelected } = action;
        const results = [...state.results];
        results[bingoNumber].isSelected = isSelected;
        return { ...state, results };
      default:
        return state;
    }
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <div ref={ref}>
        <Bingo />
        <Results />
      </div>
    </StateProvider>
  );
};

export default App;
