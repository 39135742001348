import { ReactComponent as Bingo01 } from './bingo-01.svg';
import { ReactComponent as Bingo02 } from './bingo-02.svg';
import { ReactComponent as Bingo03 } from './bingo-03.svg';
import { ReactComponent as Bingo04 } from './bingo-04.svg';
import { ReactComponent as Bingo05 } from './bingo-05.svg';
import { ReactComponent as Bingo06 } from './bingo-06.svg';
import { ReactComponent as Bingo07 } from './bingo-07.svg';
import { ReactComponent as Bingo08 } from './bingo-08.svg';
import { ReactComponent as Bingo09 } from './bingo-09.svg';

export const Bingo = [
  Bingo01,
  Bingo02,
  Bingo03,
  Bingo04,
  Bingo05,
  Bingo06,
  Bingo07,
  Bingo08,
  Bingo09,
];
