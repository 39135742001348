import React from 'react';
import ReactModal from 'react-modal';
import { FormattedMessage } from 'react-intl';

// @ts-ignore
import { white } from 'theming/colors.module.css';
import Gauge from 'assets/images/modal';

import { FormValues, ModalForm } from 'components/Modal/ModalForm';

import s from 'components/Modal/Modal.module.css';

ReactModal.setAppElement('#root');

interface Props {
  isOpen: boolean;
  onModalClose: () => void;
  onSubmit: (values: FormValues) => void;
}

const Modal = ({ isOpen, onModalClose, onSubmit }: Props): JSX.Element => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onModalClose}
      className={s.modal}
      overlayClassName={s.overlay}
    >
      <div className={s.header}>
        <div className={s.gauge}>
          <Gauge />
        </div>

        <span className={s.title}>
          <FormattedMessage id="modal.title" />
        </span>

        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          className={s.divider}
        >
          <polygon fill={white} points="0,100 100,0 100,100" />
        </svg>
      </div>

      <div className={s.content}>
        <div className={s.description}>
          <FormattedMessage id="modal.description" />
        </div>

        <ModalForm onSubmit={onSubmit} />

        <div className={s.separator} />

        <div className={s.legal}>
          <FormattedMessage
            id={'modal.legal'}
            values={{
              a: (txt: string) => (
                <a
                  href="https://www.malt.fr/about/privacy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {txt}
                </a>
              ),
            }}
          />
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
