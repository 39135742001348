import React from 'react';

const Logo = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 800 277">
    <g>
      <path
        fill={color}
        d="M187.518,84.731c-14.1-14.1-29.162-4.974-38.588,4.452l-44.52,44.523L59.885,178.23
		c-9.426,9.425-19.292,23.746-4.452,38.584c14.84,14.843,29.161,4.975,38.585-4.451l44.523-44.523l44.523-44.522
		C192.491,113.891,201.616,98.829,187.518,84.731"
      />
      <path
        fill={color}
        d="M102.445,81.084l18.855,18.854l19.192-19.192c1.303-1.306,2.626-2.518,3.957-3.661
		c-2.01-10.14-7.796-19.307-23.158-19.307c-15.391,0-21.17,9.203-23.168,19.365C99.559,78.386,100.994,79.632,102.445,81.084"
      />
      <path
        fill={color}
        d="M140.482,221.137l-19.18-19.181l-18.845,18.843c-1.431,1.432-2.855,2.74-4.273,3.969
		c2.161,10.337,8.277,19.805,23.11,19.805c14.872,0,20.98-9.519,23.127-19.889C143.102,223.549,141.781,222.436,140.482,221.137"
      />
      <path
        fill={color}
        d="M94.449,126.791h-36.35c-13.329,0-30.433,4.198-30.433,24.136c0,14.877,9.522,20.986,19.894,23.132
		C48.788,172.642,94.449,126.791,94.449,126.791"
      />
      <path
        fill={color}
        d="M195.101,127.759c-1.151,1.338-46.907,47.305-46.907,47.305h35.834c13.329,0,30.433-3.149,30.433-24.136
		C214.461,135.539,205.261,129.757,195.101,127.759"
      />
      <path
        fill={color}
        d="M106.359,114.859l6.494-6.494L94.01,89.52c-9.427-9.425-23.746-19.292-38.586-4.452
		c-10.882,10.882-8.465,21.473-2.693,30.071C54.489,115.008,106.359,114.859,106.359,114.859"
      />
      <path
        fill={color}
        d="M136.237,186.996l-6.511,6.511l19.194,19.192c9.426,9.427,24.488,18.551,38.586,4.453
		c10.52-10.521,8.106-21.571,2.29-30.423C187.924,186.864,136.237,186.996,136.237,186.996"
      />
      <path
        fill={color}
        d="M588.278,89.039c-3.219,0-8.853,0.805-15.026,3.488c-8.048,3.488-12.341,6.171-14.487,9.658
		c-8.049-9.389-18.514-12.341-30.319-12.341c-34.612,0-59.295,29.245-59.295,70.831c0,34.88,18.781,60.637,45.343,60.637
		c8.048,0,15.293-3.221,23.88-11.268c6.706-6.172,9.927-10.195,9.927-13.952c0-2.684-1.878-4.562-4.562-4.562
		c-3.757,0-7.781,3.219-12.879,3.219c-11.001,0-18.514-16.904-18.514-41.856c0-24.951,9.927-41.854,24.952-41.854
		c9.927,0,17.44,7.511,17.44,20.391v78.345c0,8.584,8.048,10.999,22.805,10.999c14.49,0,20.123-2.683,20.123-10.999V97.626
		C597.668,92.258,594.18,89.039,588.278,89.039"
      />
      <path
        fill={color}
        d="M422.736,89.039c-10.464,0-21.464,3.757-31.125,11.269c-5.365,4.293-9.927,9.122-9.927,13.147
		c0,3.487,2.414,5.365,5.903,5.365c5.904,0,7.782-2.414,13.416-2.414c5.634,0,8.317,5.366,8.317,14.757v78.614
		c0,8.584,8.05,10.999,22.805,10.999c14.489,0,20.123-2.683,20.123-10.999V123.65C452.249,103.258,440.175,89.039,422.736,89.039"
      />
      <path
        fill={color}
        d="M347.342,89.039c-11,0-20.928,3.757-30.586,11.269c-5.366,4.293-9.927,9.122-9.927,13.147
		c0,3.487,2.413,5.365,5.903,5.365c5.901,0,7.781-2.414,12.879-2.414c5.098,0,7.781,5.366,7.781,14.757v78.614
		c0,8.584,8.048,10.999,22.804,10.999c14.49,0,20.123-2.683,20.123-10.999V123.65C376.319,103.258,364.245,89.039,347.342,89.039"
      />
      <path
        fill={color}
        d="M290.999,89.039c-4.024,0-8.853,0.805-15.026,3.488c-13.415,5.903-18.512,9.658-18.512,17.976v99.273
		c0,8.584,8.048,10.999,22.805,10.999c14.49,0,20.123-2.683,20.123-10.999V97.626C300.39,92.258,296.902,89.039,290.999,89.039"
      />
      <path
        fill={color}
        d="M762.944,184.554c-10.733,2.683-13.951,3.488-17.709,3.488c-4.56,0-6.171-1.342-6.171-6.171v-67.076h24.417
		c6.439,0,8.853-2.414,8.853-8.853c0-5.366-2.414-8.051-8.853-8.051h-24.417V71.866c0-8.05-2.681-11.269-8.317-11.269
		c-5.365,0-12.879,4.83-28.977,18.514c-16.365,13.952-25.758,23.074-25.758,29.513c0,3.757,2.685,6.171,6.977,6.171h13.147v72.442
		c0,21.197,10.463,33.538,28.708,33.538c12.074,0,20.928-4.562,31.392-12.61c9.659-7.513,15.829-13.416,15.829-18.512
		C772.066,185.36,768.846,182.944,762.944,184.554"
      />
      <path
        fill={color}
        d="M654.282,32.427c-2.146,0-8.853,0.805-15.026,3.488c-13.416,5.634-18.512,9.658-18.512,17.976v155.884
		c0,8.587,8.048,11.001,22.805,11.001c14.49,0,20.122-2.683,20.122-11.001V41.013C663.671,35.646,660.184,32.427,654.282,32.427"
      />
    </g>
  </svg>
);

export default Logo;
