import { ReactComponent as Gauge01 } from './gauge-01.svg';
import { ReactComponent as Gauge02 } from './gauge-02.svg';
import { ReactComponent as Gauge03 } from './gauge-03.svg';
import { ReactComponent as Gauge04 } from './gauge-04.svg';
import { ReactComponent as Gauge05 } from './gauge-05.svg';
import { ReactComponent as Gauge06 } from './gauge-06.svg';
import { ReactComponent as Gauge07 } from './gauge-07.svg';
import { ReactComponent as Gauge08 } from './gauge-08.svg';
import { ReactComponent as Gauge09 } from './gauge-09.svg';
import { ReactComponent as Gauge10 } from './gauge-10.svg';

export const Gauges = [
  Gauge01,
  Gauge02,
  Gauge03,
  Gauge04,
  Gauge05,
  Gauge06,
  Gauge07,
  Gauge08,
  Gauge09,
  Gauge10,
];
