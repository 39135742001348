import { ReactComponent as Result01 } from './result-01.svg';
import { ReactComponent as Result02 } from './result-02.svg';
import { ReactComponent as Result03 } from './result-03.svg';
import { ReactComponent as Result04 } from './result-04.svg';
import { ReactComponent as Result05 } from './result-05.svg';
import { ReactComponent as Result06 } from './result-06.svg';
import { ReactComponent as Result07 } from './result-07.svg';
import { ReactComponent as Result08 } from './result-08.svg';
import { ReactComponent as Result09 } from './result-09.svg';

export const Results = [
  Result01,
  Result02,
  Result03,
  Result04,
  Result05,
  Result06,
  Result07,
  Result08,
  Result09,
];
