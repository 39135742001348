import React, { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { Results } from 'assets/images/results';
import s from 'components/ContentResults/ContentResults.module.css';

const generateBox = (resultNumber: number): ReactElement => {
  const BoxComponent = Results[resultNumber];
  return (
    <div className={s.box} key={resultNumber}>
      <BoxComponent />
      <span>
        <FormattedMessage
          id={`results.content.result-${resultNumber}`}
          values={{
            b: (txt: string) => <span className={s['text-bold']}>{txt}</span>,
          }}
        />
      </span>
    </div>
  );
};

const ContentResults: FunctionComponent = () => {
  return (
    <section className={s.container}>
      <div className={s.content}>
        <div className={s['grid-container']}>
          {[...Array(9)].map((_, i) => generateBox(i))}
        </div>
      </div>
    </section>
  );
};

export default ContentResults;
