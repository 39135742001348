import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

// @ts-ignore
import { white } from 'theming/colors.module.css';
import Logo from 'assets/images/logo';
import s from 'components/HeaderResults/HeaderResults.module.css';
import { Gauges } from 'assets/images/gauge';
import { useStateValue } from 'store/state';
import { getLevel } from 'utils/services';

const getResult = (level: number): string[] => {
  if ((level >= 40 && level < 60) || level < 40)
    return ['results.header.title-low', 'results.header.result-low'];
  else if (level >= 60 && level < 89)
    return ['results.header.title-medium', 'results.header.result-medium'];
  return ['results.header.title-high', 'results.header.result-high'];
};

const getGauge = (level: number) => {
  if (level > 0 && level < 46) return Gauges[0];
  else if (level >= 46 && level < 52) return Gauges[1];
  else if (level >= 52 && level < 58) return Gauges[2];
  else if (level >= 58 && level < 64) return Gauges[3];
  else if (level >= 64 && level < 70) return Gauges[4];
  else if (level >= 70 && level < 76) return Gauges[5];
  else if (level >= 76 && level < 82) return Gauges[6];
  else if (level >= 82 && level < 89) return Gauges[7];
  else if (level >= 89 && level < 94) return Gauges[8];
  return Gauges[9];
};

const HeaderResults: FunctionComponent = () => {
  const [{ results }] = useStateValue();
  const level = getLevel(results);

  return (
    <header className={s.header}>
      <div className={s.content}>
        <div className={s.logo}>
          <Logo color={white} />
        </div>
        <div className={s['result-container']}>
          <div className={s.left}>
            <span className={`${s.title} ${s.desktop}`}>
              <FormattedMessage id={getResult(level)[0]} />
            </span>
            {React.createElement(getGauge(level), null)}
          </div>
          <div className={s.right}>
            <span className={`${s.title} ${s.mobile}`}>
              <FormattedMessage id={getResult(level)[0]} />
            </span>
            <span className={s.text}>
              <FormattedMessage
                id={getResult(level)[1]}
                values={{
                  b: (txt: string) => (
                    <span className={s['text-bold']}>{txt}</span>
                  ),
                }}
              />
            </span>
          </div>
        </div>
      </div>

      <div className={s['divider-container']}>
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          className={s.divider}
        >
          <polygon fill={white} points="0,100 100,0 100,100" />
        </svg>
        <div className={s['baseline-container']}>
          <div className={s['baseline-text']}>
            <FormattedMessage id="results.header.baseline" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderResults;
