import { FormattedMessage } from 'react-intl';
import jsonp from 'jsonp';

import { Result } from 'pages/App/App.component';
import { FormValues } from 'components/Modal/ModalForm';

export const getLevel = (results: Result[]): number => {
  const MAX_LEVEL = 900;
  const riskToLevel = [20, 70, 40, 20, 70, 50, 70, 0, 30];
  const level = results.reduce((acc, result, resultNumber) => {
    return result.isSelected ? acc + riskToLevel[resultNumber] : acc + 100;
  }, 0);
  return (level / MAX_LEVEL) * 100;
};

export const generateEmptyResults = () =>
  [...Array(9)].map((_, i) => ({ isSelected: false }));

export const validateForm = (values: FormValues) => {
  const errors: { [key: string]: string | any } = {};
  if (!values.email) {
    errors.email = <FormattedMessage id={'modal.required'} />;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = <FormattedMessage id={'modal.invalid'} />;
  }
  if (!values.firstName) {
    errors.firstName = <FormattedMessage id={'modal.required'} />;
  }
  if (!values.lastName) {
    errors.lastName = <FormattedMessage id={'modal.required'} />;
  }
  if (!values.company) {
    errors.company = <FormattedMessage id={'modal.required'} />;
  }
  if (!values.employees) {
    errors.employees = <FormattedMessage id={'modal.required'} />;
  }
  return errors;
};

export const subscribeUserToMailchimpList = (email: string) => {
  const user = 'malt';
  const region = 'us5';
  const userId = '7a4393f6cc3ae72d6383d1d11';
  const listId = 'f9975ec65c';
  const url = `https://${user}.${region}.list-manage.com/subscribe/post-json?u=${userId}&id=${listId}&EMAIL=${email}`;

  jsonp(
    url,
    { param: 'c' },
    (error: any, data: { msg: string; result: string }) => {
      // if (error) {
      //   console.log(error);
      // } else if (data.result !== 'success') {
      //   console.log(data);
      // } else {
      //   console.log(data);
      // }
    },
  );
};
