import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

// @ts-ignore
import { pink, teal } from 'theming/colors.module.css';
import Logo from 'assets/images/logo';
import s from 'components/HeaderBingo/HeaderBingo.module.css';

const HeaderBingo: FunctionComponent = () => {
  return (
    <header className={s.header}>
      <div className={s.content}>
        <div className={s.left}>
          <div>
            <div className={s.logo}>
              <Logo color={pink} />
            </div>
            <span className={s.title}>
              <FormattedMessage
                id="bingo.header.title"
                values={{
                  highlight: (txt: string) => (
                    <span className={s.highlight}>{txt}</span>
                  ),
                }}
              />
            </span>
          </div>
        </div>
        <div className={s.right}>
          <div className={s.bloc}>
            <span className={s.subtitle}>
              <FormattedMessage id="bingo.header.subtitle" />
            </span>
            <span className={s.text}>
              <FormattedMessage
                id="bingo.header.1st-paraph"
                values={{
                  b: (txt: string) => (
                    <span className={s['text-bold']}>{txt}</span>
                  ),
                }}
              />
            </span>
            <span className={s.text}>
              <FormattedMessage
                id="bingo.header.2nd-paraph"
                values={{
                  b: (txt: string) => (
                    <span className={s['text-bold']}>{txt}</span>
                  ),
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className={s['divider-container']}>
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          className={s.divider}
        >
          <polygon fill={teal} points="0,100 100,0 100,100" />
        </svg>
      </div>
    </header>
  );
};

export default HeaderBingo;
