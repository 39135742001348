import React from 'react';

const CheckIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14">
    <path
      d="M5.1 10.506L1.327 6.733.043 8.01 5.1 13.066 15.957 2.21 14.682.934z"
      fill="#5563B5"
    />
  </svg>
);

export default CheckIcon;
