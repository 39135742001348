import React from 'react';

const ShareIcon = () => (
  <svg width={43} height={42} viewBox="0 0 43 42">
    <title>{'Icon / Share / Normal'}</title>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth={2} cx={21.553} cy={21} r={20} />
      <path
        d="M31.224 15.197a1.94 1.94 0 00-1.935-1.934H13.816a1.94 1.94 0 00-1.934 1.934v11.606a1.94 1.94 0 001.934 1.934h15.473a1.94 1.94 0 001.935-1.934V15.197zm-1.935 0l-7.736 4.836-7.737-4.836h15.473zm0 11.606H13.816v-9.671l7.737 4.835 7.736-4.835v9.67z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default ShareIcon;
