import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, FormikHelpers, ErrorMessage, Form, Field } from 'formik';

import { validateForm } from 'utils/services';
import DropDown from './Dropdown';

import s from 'components/Modal/Modal.module.css';

export interface FormValues {
  firstName: string;
  lastName: string;
  company: string;
  employees: string;
  email: string;
}

interface Props {
  onSubmit: (values: FormValues) => void;
}

export const ModalForm = ({ onSubmit }: Props) => {
  const intl = useIntl();

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    actions.setSubmitting(false);
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        company: '',
        employees: '',
        email: '',
      }}
      validate={validateForm}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className={s.form}>
          <div className={s['form-row']}>
            <div className={s['form-half']}>
              <div className={s['form-column-1']}>
                <Field
                  type="text"
                  name="firstName"
                  placeholder={intl.formatMessage({ id: 'modal.firstname' })}
                  className={s.input}
                />
                <ErrorMessage
                  name="firstName"
                  render={error => <div className={s.error}>{error}</div>}
                />
              </div>

              <div className={s['form-column-2']}>
                <Field
                  type="text"
                  name="lastName"
                  placeholder={intl.formatMessage({ id: 'modal.lastname' })}
                  className={s.input}
                />
                <ErrorMessage
                  name="lastName"
                  render={error => <div className={s.error}>{error}</div>}
                />
              </div>
            </div>
          </div>

          <div className={s['form-row']}>
            <div className={s['form-half']}>
              <div className={s['form-column-1']}>
                <Field
                  type="text"
                  name="company"
                  placeholder={intl.formatMessage({ id: 'modal.company' })}
                  className={s.input}
                />
                <ErrorMessage
                  name="company"
                  render={error => <div className={s.error}>{error}</div>}
                />
              </div>

              <div className={s['form-column-2']}>
                <DropDown
                  name="employees"
                  options={[
                    {
                      value: 'BETWEEN_2_AND_10',
                      label: intl.formatMessage({ id: 'modal.between_1_10' }),
                    },
                    {
                      value: 'BETWEEN_11_AND_49',
                      label: intl.formatMessage({ id: 'modal.between_11_49' }),
                    },
                    {
                      value: 'BETWEEN_50_AND_249',
                      label: intl.formatMessage({ id: 'modal.between_50_249' }),
                    },
                    {
                      value: 'BETWEEN_250_AND_999',
                      label: intl.formatMessage({
                        id: 'modal.between_250_999',
                      }),
                    },
                    {
                      value: 'BETWEEN_1000_AND_4999',
                      label: intl.formatMessage({
                        id: 'modal.between_1000_4999',
                      }),
                    },
                    {
                      value: 'MORE_THAN_5000',
                      label: intl.formatMessage({ id: 'modal.more_5000' }),
                    },
                  ]}
                  placeholder={intl.formatMessage({ id: 'modal.employees' })}
                />
                <ErrorMessage
                  name="employees"
                  render={error => <div className={s.error}>{error}</div>}
                />
              </div>
            </div>
          </div>

          <div className={s['form-row']}>
            <Field
              type="email"
              name="email"
              placeholder={intl.formatMessage({ id: 'modal.email' })}
              className={s.input}
            />
            <ErrorMessage
              name="email"
              render={error => <div className={s.error}>{error}</div>}
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className={s['cta-button']}
          >
            <FormattedMessage id={'modal.cta'} />
          </button>
        </Form>
      )}
    </Formik>
  );
};
