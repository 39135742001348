import { useField } from 'formik';
import Select from 'react-select';

// @ts-ignore
import { inkLight, teal, tealDark, tealThin } from 'theming/colors.module.css';

interface PropsType {
  name: string;
  placeholder: string;
  options: { value: string; label: string }[];
  [x: string]: any;
}

const customStyles = {
  // @ts-ignore
  option: (provided, state) => ({
    ...provided,
    color: tealDark,
    backgroundColor: state.isSelected
      ? tealThin
      : state.isFocused || state.isSubmitting
      ? tealThin
      : 'white',
    padding: 20,
    cursor: 'pointer',
  }),

  control: () => ({}),
  container: () => ({
    flex: 1,
    border: `1px solid ${inkLight}`,
    borderRadius: 26,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  menu: () => ({
    border: `1px solid ${teal}`,
    marginTop: 12,
    borderRadius: 6,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  // @ts-ignore
  indicatorsContainer: provided => ({
    ...provided,
    position: 'absolute',
    right: 0,
  }),
  // @ts-ignore
  valueContainer: provided => ({
    ...provided,
    paddingLeft: 12,
    cursor: 'default',
    alignItems: 'flex-start',
    minHeight: 34,
  }),
  // @ts-ignore
  placeholder: provided => ({
    ...provided,
    color: inkLight,
  }),
  // @ts-ignore
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: teal,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
};

const DropDown = ({ placeholder, options, ...props }: PropsType) => {
  // eslint-disable-next-line
  const [field, _, helpers] = useField(props);

  const { setValue } = helpers;

  return (
    <Select
      options={options}
      onChange={option => setValue(option!.value)}
      name={field.name}
      placeholder={placeholder}
      styles={customStyles}
      isSearchable={false}
      menuPosition="fixed"
    />
  );
};

export default DropDown;
