import React, { FunctionComponent } from 'react';

import Fade from 'hoc/Fade';
import HeaderBingo from 'components/HeaderBingo';
import ContentBingo from 'components/ContentBingo';
import FooterBingo from 'components/FooterBingo';
import { useStateValue } from 'store/state';

const Bingo: FunctionComponent = () => {
  const [{ showResults }] = useStateValue();

  if (showResults) return null;

  return (
    <Fade show>
      <HeaderBingo />
      <ContentBingo />
      <FooterBingo />
    </Fade>
  );
};

export default Bingo;
