import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ShareIcon from 'assets/images/share.js';
import ShareIconHover from 'assets/images/share-hover.js';
import RetryIcon from 'assets/images/retry.js';
import s from 'components/FooterResults/FooterResults.module.css';
import { useStateValue } from 'store/state';
import { SHOW_RESULTS } from 'store/contants';

const FooterResults: FunctionComponent = () => {
  const [{ scrollToTop }, dispatch] = useStateValue();
  const [isHover, setIsHover] = useState(false);
  const onRetry = () => {
    scrollToTop();
    dispatch({
      type: SHOW_RESULTS,
      showResults: false,
    });
  };

  return (
    <footer className={s.container}>
      <div className={s['content-background']}>
        <div className={s.content}>
          <div className={s.invitation}>
            <div
              className={s['left-invitation']}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <a
                className={s['share-wrapper']}
                href="mailto:?subject=Le guide des bonnes pratiques pour travailler avec vos prestataires externes&body=Vous voulez connaitre les bonnes pratiques légales à adopter pour travailler avec vos prestataires externes ?%0D%0AFaites ce test rapide pour les découvrir. Et profitez des recommandations des services juridiques de grandes entreprises françaises, rassemblées pour vous par Malt, la plateforme des freelances du numérique.%0D%0A%0D%0Ahttps://quizzlegal.malt.fr/"
              >
                <FormattedMessage id={'results.footer.invitation'} />
                <div className={s['share-icon-container']}>
                  <div
                    className={s['share-icon']}
                    style={{ opacity: !isHover ? 0 : 1 }}
                  >
                    <ShareIconHover />
                  </div>
                  <div
                    className={s['share-icon']}
                    style={{ opacity: isHover ? 0 : 1 }}
                  >
                    <ShareIcon />
                  </div>
                </div>
              </a>
            </div>
            <div className={s.pipe} />
            <div className={s['right-invitation']} onClick={onRetry}>
              <RetryIcon />
              <span className={s.underline}>
                <FormattedMessage id={'results.footer.retry'} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={s['subfooter-background']}>
        <div className={s['subfooter-content']}>
          <span className={s.risk}>
            <FormattedMessage id={'results.footer.risk'} />
          </span>
          <span className={s.solution}>
            <FormattedMessage
              id={'results.footer.solution'}
              values={{
                a: (txt: string) => (
                  <a
                    href="https://www.malt.fr/companies/legal"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {txt}
                  </a>
                ),
              }}
            />
          </span>
          <a
            href="https://www.malt.fr/ask-demo"
            rel="noopener noreferrer"
            target="_blank"
          >
            <button className={s.cta}>
              <FormattedMessage id={`results.footer.contact`} />
            </button>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterResults;
