import React, { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ShareIcon from 'assets/images/share.js';
import ShareIconHover from 'assets/images/share-hover.js';
import s from 'components/FooterBingo/FooterBingo.module.css';

const FooterBingo: FunctionComponent = () => {
  const [isHover, setIsHover] = useState(false);
  return (
    <footer>
      <div className={s['content-background']}>
        <div className={s.content}>
          <div
            className={s.invitation}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <a href="mailto:?subject=Le guide des bonnes pratiques pour travailler avec vos prestataires externes&body=Vous voulez connaitre les bonnes pratiques légales à adopter pour travailler avec vos prestataires externes ?%0D%0AFaites ce test rapide pour les découvrir. Et profitez des recommandations des services juridiques de grandes entreprises françaises, rassemblées pour vous par Malt, la plateforme des freelances du numérique.%0D%0A%0D%0Ahttps://quizzlegal.malt.fr/">
              <FormattedMessage id={'bingo.footer.invitation'} />

              <div
                className={s['share-icon']}
                style={{ opacity: !isHover ? 0 : 1 }}
              >
                <ShareIconHover />
              </div>
              <div
                className={s['share-icon']}
                style={{ opacity: isHover ? 0 : 1 }}
              >
                <ShareIcon />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className={s['subfooter-background']}>
        <div className={s['subfooter-content']}>
          <div className={s.legal}>
            <FormattedMessage
              id={'bingo.footer.legal'}
              values={{
                b: (txt: string) => (
                  <span className={s['text-bold']}>{txt}</span>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterBingo;
